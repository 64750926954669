.product{
    display: flex;
    border-bottom: 1px solid lightgray;
    

}
.product-name{
    margin-bottom:20px;
}
.product-image img{
    margin-right: 10px;
    
}
.product-name h3{
    color: blue;
    font-weight: 400;
}
.btn-regular{
    width: 150px;
    height: 30px;
    background-color:goldenrod;
    border: 1px solid lightgray;
    border-radius:5px;
    cursor: pointer;
    font-weight: 400;
}

