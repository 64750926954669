.search-container{
    background-color: rgba(29, 29, 29, 0.918);
    text-align: center;
    line-height: 60px;
}
.search-container input{
    width: 75%;
    height: 30px;
    font-size: large;
    border-radius: 5px;
}
.shop-container{
    display:grid;
    grid-template-columns: 3fr 1fr;

}
.product-container{
    border-right: 1px solid lightgray;
    margin-left: 20px;
    margin-right: 5px;
}
.card-container{
    width: 100%;
}