.header{
    text-align: center;
}
.logo{
    height:60px;
    margin: 30px;
    
}
.header nav{
    background-color: black;
    line-height: 40px;
} 
.header nav a{
    text-decoration: none;
    margin-right: 20px;
    color: white;
    padding:5px;
    font-size: 20px;
}
.header nav a:hover{
    background-color: lightgray;
    color: black;
}